import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTransitionColors, useTransitionImage } from "@hooks";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { BrandComponent } from "../common/BrandComponent";
import Image from "../common/Image";
import "./default.module.scss";
import { colorActions } from "../../redux/slices/color";
import { virtualMirrorActions } from "../../redux/slices/virtualMirror";

interface LensPreviewTransitionOpt {
  lensImage?: string;
}

export function LensPreviewTransition(props: LensPreviewTransitionOpt) {
  const indoor = useTranslate("steps.transitionColor.indoor");
  const outdoor = useTranslate("steps.transitionColor.outdoor");
  const transitionSelected = useSelector(
    (state: any) => state.color.transitionSelected
  );
  const configProjectImage = useSelector(
    (state: any) => state.config?.baseURLs?.projectImage
  );
  const [middleValue, setMiddleValue] = useState("50");
  const [currentOpacity, setCurrentOpacity] = useState(0.5);

  const { urlImage: transitionImages, isLoading } = useTransitionImage();

  const { isNestedTransitionSwatchEnabled } = useTransitionColors(null);

  const largeIconBasepath = useSelector(
    (state: any) =>
      `${state.config.baseURLs.assetsCDN || ""}/extra/image/rxc/icons/`
  );

  const colorSelected = useSelector((state: any) => state.color.colorSelected);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    let opacity = e.target.value / 100;
    setMiddleValue(e.target.value);
    setCurrentOpacity(opacity);
  };
  const activateVirtualMirror = () => {
    dispatch(virtualMirrorActions.setShowVirtualMirror(true));
    // try {
    //   //@ts-ignore
    //   window.tealium_data2track.push({
    //     id: "Event",
    //     data_element_id: "LensPanel_TreatmentDetail-VMStart",
    //   });
    // } catch (error) {
    //   console.error(
    //     "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
    //   );
    // }
  };
  useEffect(() => {
    setMiddleValue("50");
  }, [transitionSelected]);

  useEffect(() => {
    if (props.lensImage) {
      setCurrentOpacity(0.5);
    }
  }, [props.lensImage]);

  useEffect(() => {
    if (
      transitionImages?.frame &&
      transitionImages?.lens &&
      transitionImages.logo
    ) {
      let compositeUrl = `${transitionImages.frame}?impolicy=CompositeLogo&Lenses=${transitionImages.lens}&Logo=${transitionImages.logo}`;
      console.log(compositeUrl);
      dispatch(colorActions.setUrlImageGlobal(compositeUrl));
    }
  }, [transitionImages]);

  const isProductImageFallback = useSelector(
    (state: any) => state.color.isProductImageFallback
  );

  return isLoading ? (
    <BrandComponent componentName="Loader" parameter={{ greyLoader: true }} />
  ) : (
    <>
      <div
        className={`LensPreviewTransaction__container ${!transitionImages.frame && isNestedTransitionSwatchEnabled
          ? "fade-shadow"
          : ""
          }`}
      >
        {transitionImages.frame ? (
          <img
            src={transitionImages.frame}
            className={
              "LensPreviewTransaction__frame" +
              (!transitionImages.lens ? " no-absolute" : "")
            }
          />
        ) : isNestedTransitionSwatchEnabled ? (
          <Image
            imageClass="LensPreviewTransaction__white_img blend"
            url={largeIconBasepath}
            fileName={"rxc-icon-treatments-clear"}
          />
        ) : (
          <Image
            imageClass="LensPreviewTransaction__white_img"
            url={configProjectImage}
            fileName={"white-lens"}
          />
        )}
        {transitionImages.lens ? (
          <>
            <img
              src={
                transitionImages.lens /* "https://assets.lenscrafters.com/is/image/LensCrafters/713132838167__002.png" */
              }
              className="LensPreviewTransaction__lens"
              style={{ opacity: currentOpacity }}
            />
            {transitionImages.logo && (
              <img
                src={
                  transitionImages.logo /* "https://assets.lenscrafters.com/is/image/LensCrafters/713132838167__002.png" */
                }
                className={
                  "LensPreviewTransaction__frame" +
                  (!transitionImages.lens ? " no-absolute" : "")
                }
              />
            )}
            {(() => {
              dispatch(dispatch(colorActions.setIsProductImageFallback(false)));
              //console.log("isProductImageFallback 1", isProductImageFallback);
            })()}
          </>
        ) : !transitionImages.frame && !isNestedTransitionSwatchEnabled ? (
          <Image
            imageClass="LensPreviewTransaction__overlay"
            url={configProjectImage}
            fileName={props.lensImage}
            style={{ opacity: currentOpacity }}
          />
        ) : !transitionImages.frame && isNestedTransitionSwatchEnabled ? (
          <>
            <Image
              imageClass="LensPreviewTransaction__overlay blend"
              url={largeIconBasepath}
              fileName={props.lensImage}
              style={{ opacity: currentOpacity }}
            />
            {(() => {
              dispatch(dispatch(colorActions.setIsProductImageFallback(true)));
              //console.log("isProductImageFallback 2", isProductImageFallback);
            })()}
          </>
        ) : null}
      </div>
      {((transitionImages.frame &&
        transitionImages.lens &&
        !transitionImages.error) ||
        transitionImages.error) && (
          <div className="LensPreviewTransaction_range__container">
            <span className="LensPreviewTransaction_range__image_left">
              {indoor}
            </span>
            <input
              id="slider-opacity"
              aria-label="Lens color slider"
              className="lens-slider range opacity5"
              type="range"
              min="0"
              max="100"
              defaultValue={middleValue}
              value={middleValue}
              step="1"
              onChange={(e) => handleChange(e)}
              tabIndex={0}
            />
            <span className="LensPreviewTransaction_range__image_right">
              {outdoor}
            </span>
          </div>
        )}
      {colorSelected?.clen ? (
        <BrandComponent
          componentName="VirtualMirrorButton"
          parameter={{
            onClick: () => {
              activateVirtualMirror();
            },
          }}
        />
      ) : null}
    </>
  );
}
