import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { workflowActions } from "../../redux/slices/workflow";
import { prescriptionActions } from "../../redux/slices/prescription";
import {
  callInsuranceService,
  checkGVP,
  checkGVPForType,
  getCheapPackageFromList,
  getFrameOnlyKey,
  getStepBadgeType,
} from "../ConfigLoader";
import { Parameter } from "../models/Parameter";
import { workflow } from "../models/Workflow";
import { useConfigContent } from "./useConfigContent";
import { useCurrentPackages } from "./useCurrentPackages";
import { useDistanceAndReading } from "./useDistanceAndReading";
import {
  useCurrentPrices,
  useCurrentType,
  useEnableBrandLastStep,
  useMixAndMatch,
  usePriceStepItem,
  useShowFramePlusLensesPrice,
} from "@hooks";
import { useCurrentBrand } from ".";

type Type = {
  id;
  title;
  description;
  imageFileName;
  sequence;
  badge;
};

export function useTypes() {
  const typeContent = useConfigContent("Type");
  const dispatch = useDispatch();
  const learnMoreContent = useSelector(
    (state: any) => state.config?.actionsModule?.loadLearnMoreContent
  );
  const showGrayoutTile = useSelector(
    (state: any) => state.config?.layoutSettings?.showGrayoutTile
  );
  const isMixAndMatchStep = useMixAndMatch();
  const brand = useCurrentBrand();
  const currentPackages = useCurrentPackages();
  const BrandLastStep = useEnableBrandLastStep();
  const configPackages = useSelector(
    (state: any) => state.config?.lensesData?.packages
  );
  const configData = useSelector((state: any) => state.config?.data);
  const currentType = useCurrentType();
  const configPrescription = useSelector(
    (state: any) => state.config?.prescriptionModule
  );
  const currentRxType = useSelector(
    (state: any) => state.prescription?.currentRxType
  );
  const prescriptionTypes = useSelector(
    (state: any) => state.config?.prescriptionTypes
  );
  const { isDistanceAndReadingActive } = useDistanceAndReading();
  const getPriceStep = usePriceStepItem();
  const configFrame = useSelector((state: any) => state.config?.data?.frame);
  const showFramePlusLensesPrice = useShowFramePlusLensesPrice();
  const reduxDispatch = useDispatch();

  const getSingleVisionId = useCallback(
    () =>
      currentPackages.find((currentPackage) =>
        currentPackage.lensPackage.type.toLowerCase().includes("single")
      )?.type,
    [currentPackages]
  );

  const configContent = useSelector(
    (state: any) => state.config?.lensesData?.content
  );

  const configInsurance = useSelector(
    (state: any) => state.config?.insuranceModule
  );

  const isInsuranceEnabled = useSelector(
    (state: any) => state.insurance.insuranceEnabled
  );

  const currentPrices = useCurrentPrices();

  const frame = useSelector((state: any) => state.config?.data?.frame);

  const hasFrameOnly = Object.keys(typeContent).some(key => typeContent[key]?.id?.toLowerCase().includes("frame"));

  useEffect(() => {
    if (isInsuranceEnabled && !hasFrameOnly) {
      let filteredPackages = currentPackages.filter(
        (pkg) => !pkg.lensPackage.type.toLowerCase().includes("frame")
      );

      let filterByCurrentRxType = filteredPackages.filter(
        (pkg) => pkg.lensPackage.type.toLowerCase() === currentRxType.toLowerCase()
      );

      let cheapestPackages = null;
      if (filterByCurrentRxType.length > 1) {
        cheapestPackages = [getCheapPackageFromList(filterByCurrentRxType)];
      } else {
        cheapestPackages = filterByCurrentRxType;
      }

      let insurablePackages = cheapestPackages.filter(
        (pkg) => pkg?.lensPackage?.insurable
      );


      if (insurablePackages.length) {
        callInsuranceService(
          cheapestPackages,
          configInsurance,
          frame,
          dispatch
        );
      }

      //console.log(currentPrices);

    }
  }, [currentPrices, currentPackages]);

  // useEffect(() => {
  //   if (isInsuranceEnabled) {
  //     let filteredPackages = currentPackages.filter((p) => {
  //       return p.lensPackage.type.toLowerCase().includes("only");
  //     });

  //     let insurablePackages = filteredPackages.filter(
  //       (pkg) => pkg.lensPackage.insurable
  //     );

  //     if (insurablePackages.length) {
  //       callInsuranceService(
  //         filteredPackages,
  //         configInsurance,
  //         frame,
  //         dispatch
  //       );
  //     }

  //     console.log(currentPrices);
  //   }
  // }, [isInsuranceEnabled]);


  const getSingleVisionIdContent = useCallback(
    () =>
      typeContent.find((type) => type.id.toLowerCase().includes("single"))?.id,
    [typeContent]
  );

  const onTypeSelected = useCallback(
    (id: any, skipped?: boolean, preSelected?: boolean) => {

      dispatch(prescriptionActions.setCurrentRxType(id));

      if (currentRxType?.toLowerCase() === "reading") {
        dispatch(prescriptionActions.setCurrentRxType(null));
      }
      if (["Non_prescription", "Frame Only"].includes(id))
        reduxDispatch(prescriptionActions.reset());
      // reset promo - done to fix case in which Single Vision is a GVP promo while
      // Progressive and Frame Only aren't, so clicking on Frame Only here will go
      // to review and perform some promo logic.
      reduxDispatch(workflowActions.setPromoType(null));

      if (id === getFrameOnlyKey(configPackages, configData)) {
        reduxDispatch(workflowActions.setLensType(id));
        //TODO verificare che serva per i sun, altri sun.something non ce l'hanno
        /* const frameOnlyConfig = configs.find((c) => c.id.indexOf("FRAME") >= 0);
        if (frameOnlyConfig) {
          id = frameOnlyConfig.id;
        } */
        workflow.goToStep(
          "Review",
          [
            {
              key: "type",
              value: id,
            },
          ],
          skipped
        );
      } else {
        //TODO use constants
        const isDistanceAndReading = ["reading", "distance"].includes(
          id?.toLowerCase()
        );
        const parameters: Parameter[] = [
          {
            key: "type",
            value: id,
          },
        ];
        if (isDistanceAndReading)
          parameters[0].optionalAttributes = {
            key: "reading&distance",
            value: id,
          };

        if (
          (configPrescription?.prescriptionType === prescriptionTypes.FULL ||
            configPrescription?.prescriptionType ===
            prescriptionTypes.NESTED_CA ||
            configPrescription?.prescriptionType === "NESTED" ||
            configPrescription?.prescriptionType === "NESTED_CA") &&
          id !== "Non_prescription"
        ) {
          if (parameters?.[0]?.value) {
            reduxDispatch(workflowActions.setLensType(parameters?.[0]?.value));
            workflow.goNextStep(parameters, skipped);
          }
        } else if (currentType === "SUNGLASSES") {
          if (id !== "Non_prescription") {
            workflow.goNextStep(parameters, skipped);
          } else {
            workflow.goToStep("LensColor", parameters, false);
          }
        } else if (BrandLastStep) {
          if (checkGVPForType(currentPackages, "Non_prescription")) {
            reduxDispatch(workflowActions.setPromoType("GVP"));
            workflow.goToStep("GVPTreatment", parameters, skipped);
          } else {
            workflow.goToStep("TreatmentsFamily", parameters, skipped);
          }
        } else {
          workflow.goToStep("Brand", parameters, skipped);
        }
      }
    },
    [getSingleVisionId]
  );

  const types = useMemo(() => {
    if (currentPackages && typeContent && configPackages && configData) {
      //remap typeContent
      const extendedTypeContent = typeContent.map((content) => ({
        ...content,
        imageUrl: content.imageFileName,
        noMarginTitle: true,
        badge: content.badge && getStepBadgeType(content.badge as string),
        textBadge: content.badge ? content.badge : "",
        disabled: content.badge == "coming soon" ? true : false,
        moreInfo:
          brand === "clearly" && content.contentIdentifier
            ? learnMoreContent
            : false,
        onClick: () =>
          content.badge !== "coming soon" &&
          !content.disabled &&
          onTypeSelected(content.id),
      }));
      //filter the type section in lensesData.content looking in currentPackages

      let types = extendedTypeContent
        .filter((content) => {
          if (!isMixAndMatchStep) {
            return (
              currentPackages.some(
                (cp) => cp.lensPackage.type === content.id
              ) || content.id === showGrayoutTile?.type
            );
          } else {
            return configPackages.some(
              (cp) => cp.lensPackage.type === content.id
            );
          }
        })
        //filter frameOnly
        .filter((content) => {
          const showFrameOnly =
            configData.showFrameOnly == false ? false : true;

          const configFrameOnly = getFrameOnlyKey(configPackages, configData);

          if (content.id !== configFrameOnly) return true;
          return showFrameOnly;
        });

      if (showFramePlusLensesPrice) {
        types = types.map((type) => {
          let priceObj = getPriceStep({ type: type.id });
          if (type.id === "Frame Only") {
            priceObj = {
              price: configFrame.offerPrice,
              discount: 0,
              insPrice: 0,
            };
            return { ...type, ...priceObj, ignorePriceLabels: true };
          }
          return { ...type, ...priceObj };
        });
      }

      if (!isDistanceAndReadingActive) return types;

      const reading = extendedTypeContent.find(
        (content) => content.id === "Reading"
      );
      const distance = extendedTypeContent.find(
        (content) => content.id === "Distance"
      );
      //looking for reading and distance in content
      if (!reading || !distance) {
        return types;
      }

      return (
        ([distance, reading] as { [key: string]: any }[])
          .concat(...types)
          //remove single vision
          .filter((type) => type.id != getSingleVisionIdContent())
          .sort((a, b) =>
            a.sequence > b.sequence ? 1 : b.sequence > a.sequence ? -1 : 0
          )
      );
    }
  }, [
    typeContent,
    currentPackages,
    configPackages,
    configData,
    getSingleVisionId,
    isDistanceAndReadingActive,
    isMixAndMatchStep,
    showGrayoutTile,
    configFrame,
    getPriceStep,
    showFramePlusLensesPrice,
  ]);

  return { types: types, onSelect: onTypeSelected };
}
