import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type SwatchImage = {
  url: string;
  name: string;
  value: string;
};
type DAMType = {
  isDAM: boolean;
  error: any;
  retriveFullPath: (_: string) => string;
  imagePaths: any;
};
export type StateColor = {
  colorSelected: string;
  colorCategory?: string;
  colorDesc?: string;
  transitionSelected: string;
  hasImageFileName: string;
  isError: boolean;
  showFallbackImageMessage: boolean;
  showNotFoundImageMessage: boolean;
  recommendedUseSelected: string;
  lensBrand?: string;
  swatchImage?: SwatchImage;
  DAM?: DAMType;
  suitableForDriving: boolean;
  frameImgUrl?: string;
  isLoading: boolean;
  urlImage: string;
  clearClen?: string;
  fallbackActive?: boolean;
  isProductImageFallback?: boolean;
};

const initialState: StateColor = {
  colorSelected: null,
  colorDesc: null,
  colorCategory: null,
  transitionSelected: null,
  hasImageFileName: null,
  isError: false,
  showFallbackImageMessage: false,
  showNotFoundImageMessage: false,
  recommendedUseSelected: null,
  lensBrand: null,
  swatchImage: null,
  DAM: undefined,
  suitableForDriving: true,
  isLoading: false,
  urlImage: "",
  frameImgUrl: "",
  clearClen: "",
  fallbackActive: false,
  isProductImageFallback: false
};

const colorSlice = createSlice({
  name: "colorSelected",
  initialState: initialState,
  reducers: {
    setUrlImageGlobal: (state, { payload }) => {
      state.urlImage = payload;
    },
    setFallbackActive: (state, { payload }) => {
      state.fallbackActive = payload;
    },
    setIsProductImageFallback: (state, { payload }) => {
      state.isProductImageFallback = payload;
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setSuitableForDriving: (state, { payload }) => {
      state.suitableForDriving = payload;
    },
    selectColor: (state, { payload }: PayloadAction<string>) => {
      state.colorSelected = payload;
    },
    selectColorCategory: (state, { payload }: PayloadAction<string>) => {
      state.colorCategory = payload;
    },
    selectColorDesc: (state, { payload }: PayloadAction<string>) => {
      state.colorDesc = payload;
    },
    selectRecommendedUse: (state, { payload }: PayloadAction<string>) => {
      state.recommendedUseSelected = payload;
    },
    selectTransition: (state, { payload }: PayloadAction<string>) => {
      state.transitionSelected = payload;
    },
    setHasImageFileName: (state, { payload }: PayloadAction<any>) => {
      state.hasImageFileName = payload;
    },
    setError: (state, { payload }: PayloadAction<boolean>) => {
      state.isError = payload;
    },
    setShowFallbackImageMessage: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showFallbackImageMessage = payload;
    },
    setShowNotFoundImageMessage: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showNotFoundImageMessage = payload;
    },
    setLensBrand: (state, action: PayloadAction<string>) => {
      state.lensBrand = action.payload;
    },
    setSwatchUrl: (state, { payload }: PayloadAction<SwatchImage>) => {
      state.swatchImage = payload;
    },
    setDAMObj: (state, { payload }: PayloadAction<DAMType>) => {
      state.DAM = payload;
    },
    setFrameImgUrl: (state, action: PayloadAction<string>) => {
      state.frameImgUrl = action.payload;
    },
    setClearClen: (state, action: PayloadAction<string>) => {
      state.clearClen = action.payload;
    },
    reset: (state, { payload }: PayloadAction<string>) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
  },
});

export const colorReducer = colorSlice.reducer;
export const colorActions = colorSlice.actions;
