import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Prescription } from "../../types";

export type PrescriptionState = {
  currentPrescription: Prescription;
  previousPrescription: Prescription;
  callMyDoctorParams: object;
  uploadFileDataPreview: any;
  isLoadedWithoutEdit: boolean;
  isPrismPriceAdded: boolean;
  currentRxType: string;
};

const initialState: PrescriptionState = {
  currentPrescription: null,
  previousPrescription: null,
  callMyDoctorParams: null,
  uploadFileDataPreview: null,
  isLoadedWithoutEdit: false,
  isPrismPriceAdded: false,
  currentRxType: "",
};

const PrescriptionSlice = createSlice({
  name: "prescription",
  initialState: initialState,
  reducers: {
    setCurrentPrescription: (
      state,
      { payload }: PayloadAction<Prescription>
    ) => {
      state.currentPrescription = payload;
    },
    setCurrentRxType: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.currentRxType = payload;
    },
    setPreviousPrescription: (
      state,
      { payload }: PayloadAction<Prescription>
    ) => {
      state.previousPrescription = payload;
    },
    setCallMyDoctorParams: (state, { payload }: PayloadAction<Object>) => {
      state.callMyDoctorParams = payload;
    },
    setUploadFileDataPreview: (state, { payload }: PayloadAction<Object>) => {
      state.uploadFileDataPreview = payload;
    },
    setLoadedWithoutEdit: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadedWithoutEdit = payload;
    },
    setIsPrismPriceAdded: (state, { payload }: PayloadAction<boolean>) => {
      state.isPrismPriceAdded = payload;
    },
    reset: (state, { payload }: PayloadAction<string>) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
  },
});

export const prescriptionReducer = PrescriptionSlice.reducer;
export const prescriptionActions = PrescriptionSlice.actions;
