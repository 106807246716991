import { useDispatch, useSelector } from "react-redux";
import { useProductImage } from "./useProductImage";
import { colorActions } from "../../redux/slices/color";

export function useImagery() {
  const showFallbackImageMessage = useSelector(
    (state: any) => state.color?.showFallbackImageMessage
  );
  const swatchImage = useSelector((state: any) => state.color.swatchImage);

  // const { urlImage: productImageUrl } = useProductImage();
  const productImageUrl = useSelector((state: any) => state.color.urlImage);
  const frameImageUrl = useSelector((state: any) => state.config?.data?.frame?.imageUrl);

  const isProductImageFallback = useSelector(
    (state: any) => state.color.isProductImageFallback
  );

  const dispatch = useDispatch();

  if (isProductImageFallback === true) {
    dispatch(colorActions.setShowFallbackImageMessage(true));
  }
  else {
    dispatch(colorActions.setShowFallbackImageMessage(false));
  }

  const getImagery = (pdImageUrl, swatchImageObj) => {
    let url = null;
    if (swatchImage && showFallbackImageMessage && productImageUrl) {
      url = swatchImageObj.url.split(",")[0].split(" ")[0];
    }
    return {
      productImage: url ? frameImageUrl : pdImageUrl || frameImageUrl,
      fallbackImage: url,
    };
  };

  return getImagery(productImageUrl, swatchImage);
}
